import React, { createContext, useContext, useState, useEffect } from 'react';

const SubdomainContext = createContext();

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  return parts.length >= 2 ? parts[0] : null;
};

const SubdomainProvider = ({ children }) => {
  const [subdomain, setSubdomain] = useState(getSubdomain());

  useEffect(() => {
    const handleSubdomainChange = () => {
      setSubdomain(getSubdomain());
    };

    window.addEventListener('popstate', handleSubdomainChange);
    return () => window.removeEventListener('popstate', handleSubdomainChange);
  }, []);

  return (
    <SubdomainContext.Provider value={subdomain}>
      {children}
    </SubdomainContext.Provider>
  );
};

const useSubdomain = () => { 
    return useContext(SubdomainContext) 
};

export { SubdomainProvider, useSubdomain };