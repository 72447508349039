import React, { lazy, Suspense } from 'react';
import './App.css';

import {useSubdomain} from './SubdomainProvider';


const App = () => {
  const subdomain = useSubdomain();

  const AppComponent = lazy(() => import('./AppComponent'));
  const WwwComponent = lazy(() => import('./WwwComponent'));


  const Loading = () => {
    return (
      <div className={'loadingMainContainer'}>
        <div className={'loadingContainer'}>
          <div className={'loading'}></div>
          <div className={'loadingText'}>
            Loading...
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <Suspense fallback={<Loading />}>
      {subdomain === 'www' && <WwwComponent />}
          {subdomain === 'app' && <AppComponent />}
          {!subdomain && <AppComponent />}
    </Suspense>
  );

}


export default App;
