import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "129762b5-0ecb-469b-9799-23044c67d5a2", // From Azure AD application
        authority: "https://login.microsoftonline.com/43e114b5-cc23-4ba6-8590-a777bf95a251", // Replace 'your-tenant-id'
        // redirectUri: "https://app.localhost:3000",
        // redirectUri: "https://tobit.azurewebsites.net",
        redirectUri: "https://app.thejyra.com",
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);