import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from "./registerServiceWorker";
import { msalInstance } from './pages/login/useMsal';
import { MsalProvider } from "@azure/msal-react";
import { SubdomainProvider } from './SubdomainProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <SubdomainProvider>
        <App />
      </SubdomainProvider>
    </MsalProvider>
  </React.StrictMode>
);
registerServiceWorker();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
